import React, { Component } from "react";
import "./App.css";
import "./services/fakeGenreService.js";
import { getMovies } from "./services/fakeMovieService";

class App extends Component {
  state = {
    statemovies: getMovies()
  };
  getMoviesCount() {
    return this.state.statemovies.length;
  }
  deleteRow(id) {
    console.log("here" + id);
  }
  render() {
    console.log(this.state.statemovies);

    return (
      <main className="container">
        <h2>Showing {this.getMoviesCount()} movie in the database.</h2>
        <table className="table">
          <thead>
            <tr>
              <th>Title</th>
              <th>Genre</th>
              <th>Stock</th>
              <th>Rate</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {this.state.statemovies.map((movie, index) => (
              <tr key={movie._id}>
                <td>{movie.title}</td>
                <td>{movie.genre.name}</td>
                <td>{movie.numberInStock}</td>
                <td>{movie.dailyRentalRate}</td>
                <td>
                  <button
                    className="btn btn-danger"
                    //onClick={this.deleteRow(movie._id)}
                    onClick={() => this.deleteRow(movie._id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </main>
    );
  }
}

export default App;
